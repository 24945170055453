import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import { getStatus } from "../../services/Helper";
import { notification } from "antd";

const PharmaOrderTable = ({ orders }) => {

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', options).replace(',', ' at');
  };
  return (
    <>
      <div className="">
        <table className="table table-hover align-middle table-nowrap mb-0 table-borderless">
          <thead className="table-light">
            <tr>
              <th className="align-middle w-20" scope="col">
                #ID
              </th>
              <th className="align-middle w-20" scope="col">
                Customer
              </th>
              <th className="align-middle w-30" scope="col">
                Products
              </th>
              <th className="align-middle" scope="col">
                Total
              </th>
              <th className="align-middle" scope="col">
                Status
              </th>
              <th className="align-middle" scope="col">
                Date
              </th>
              <th className="align-middle" scope="col"> </th>
              <th className="align-middle" scope="col"></th>
              <th className="align-middle text-center" scope="col">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={index}>
                <td>
                  <Link to={`/orders/${order._id}`}>{order.orderId}</Link>
                </td>
                <td className="text-body-emphasis">
                  {order.buyerInfo.fullname
                    ? order.buyerInfo.fullname
                    : order.buyerInfo.username}{" "}
                    <span style={{fontSize:'12px',fontWeight:'200'}}>({order?.buyerInfo?.username})</span>
                </td>
                <td className="text-body-emphasis">
                  {order.products && order.products.length > 0 ? (
                    <>
                  {order.products.map((product, index) => (
                    <div key={index} style={{ borderBottom: index !== order.products.length - 1 ? '1px dashed #e5e5e2' : 'none'}}>
                      <p className="p-0 m-0" style={{fontSize:'11px'}}>{product.title}</p>
                    </div>
                  ))}

                    </>
                  ) : (
                    <span>No products found</span>
                  )}
                </td>
                <td>£{order.total}</td>
                <td>
                <span
                  className={`badge rounded-lg rounded-pill alert py-3 px-4 mb-0 border-0 text-capitalize fs-12 ${getStatus(order.status).class}`}
                >
                  {getStatus(order.status).text}
                </span>
                </td>
                <td>{formatDate(order.createdAt)} </td>
                <td>
                    <>
                    <div className="d-flex">
                      {order.notification === 1 &&
                        <div style={{margin:'10px'}}>
                          <i class="fa fa-circle text-danger-glow-green blink"></i>
                        </div>
                      }
                      {order.anomalyVisible && 
                        <div style={{margin:'10px'}}>
                          <i class="fa fa-circle text-danger-glow-red blink"></i>
                        </div>
                      }
                      {order.isRepeat && 
                        <div style={{margin:'10px'}}>
                          <i class="fa fa-circle text-danger-glow-purple blink"></i>
                        </div>
                      }

                    </div>
                    </>
                  
                </td>
                <td>
                  {order.isNotes && 
                    <>
                      <i className="fas fa-sticky-note text-primary" data-tooltip-id={`notesTooltip-${index}`}></i>
                      <Tooltip id={`notesTooltip-${index}`} place="top" effect="solid" className="custom-tooltip">
                              {order.notes.map((note, noteIndex) => (
                                <>
                                  <div className=" mt-4" key={noteIndex}>
                                          <p className="m-0 p-0 note-title">{note.title}</p>
                                          <p className=" m-0 p-0 note-date">{note.description} </p>
                                          <div className="note-footer mt-4 mb-4">
                                              <p className="m-0 p-0 note-date">  
                                                {formatDate(note.createdAt)} 
                                              </p>
                                          </div>
                                  </div>
                                  <hr />
                                  </>
                              ))}
                      </Tooltip>
                    </>

                  }
                </td>
                <td className="text-center">
                  <div className="d-flex flex-nowrap justify-content-center">
                    <Link to={`/orders/${order._id}`}
                      className="btn btn-outline-primary py-4 fs-13px btn-xs me-4"
                    >
                      <i className="fa fa-info"></i>
                    </Link>
                    
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PharmaOrderTable;
