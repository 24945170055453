import { useState, useEffect, useRef } from 'react';
import { DateTimeFormater } from "../../../services/Helper";
import { Link } from 'react-router-dom';
import { get, post } from "../../../services/ApiService";

const ChatHistory = ({ messages, isChatLoading, isNoMessage, NoMessageText }) => {
    const chatContainerRef = useRef(null);
    const prevMessagesRef = useRef([]);
    const [loadingButtons, setLoadingButtons] = useState({});

    // State to store messages and trigger re-render when updated
    const [chatMessages, setChatMessages] = useState(messages);

    useEffect(() => {
        if (chatContainerRef.current && prevMessagesRef.current.length !== chatMessages.length) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
        prevMessagesRef.current = chatMessages; 
    }, [chatMessages]);

    // Function to update the read status of the message
    const MarkReadMessage = async (id) => {
        try {
            setLoadingButtons((prev) => ({ ...prev, [id]: true })); // Set loading for this button
            const res = await get(`api/messages/mark-as-read/${id}`);
            if (res.success) {
                setChatMessages((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg._id === id ? { ...msg, read: true } : msg
                    )
                );
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingButtons((prev) => ({ ...prev, [id]: false })); // Clear loading for this button
        }
    };

    return (
        <>
            <div className="row g-3 h-fix" ref={chatContainerRef}>
                {!isChatLoading && chatMessages && 
                    chatMessages.map((msg, index) => (
                        <div key={index} className="col-lg-12" style={{ marginTop: '10px' }}>
                            <div className={`d-flex align-items-center gap-3 justify-content-${msg.sender !== 'pillpharm' ? 'start' : 'end'}`}>
                                <div>
                                    <p className={`m-0 ${msg.sender !== 'pillpharm' ? '' : 'text-end'}`}>
                                        {msg.sender !== 'pillpharm' ? msg.sender : 'Pillpharm'}
                                    </p>
                                    <div className={`${msg.sender !== 'pillpharm' ? 'left-block text-white' : 'right-block'} fs-14`}>
                                        <span>{msg.body}</span>
                                        <div className="d-flex justify-content-between align-items-center mt-2">
                                            {msg.sender !== 'pillpharm' ? (
                                            <>
                                                <p className="m-0 time text-start">
                                                    <span>{DateTimeFormater(msg.creationDate)}</span>
                                                </p>
                                                {!msg.read &&
                                                    <Link
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            MarkReadMessage(msg._id);
                                                        }}
                                                        disabled={loadingButtons[msg._id]}
                                                        title="Mark As Read"
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            borderRadius: '50%',
                                                            background: 'white',
                                                            textAlign: 'center',
                                                            lineHeight: '22px',
                                                        }}
                                                    >
                                                        {loadingButtons[msg._id] ? (
                                                            <i className="fas fa-spinner fa-spin" />
                                                        ) : (
                                                            <i className="fas fa-book-reader" />
                                                        )}
                                                    </Link>
                                                }
                                            </>
                                            ) : (
                                            <p className={`m-0 time text-dark`}>
                                                <span>{DateTimeFormater(msg.creationDate)}</span>
                                            </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {isNoMessage &&
                <div className='col-12 text-center'>
                    <p className="text-danger">{NoMessageText === '' ? 'No message found' : NoMessageText}</p>
                </div>
            }
        </>
    );
};

export default ChatHistory;
